import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

type WeaponType = 'sns' | 'longsword' | 'wand' | 'bow' | 'crossbow' | 'dagger' | 'staff';

interface GuildMember {
  id: string;
  name: string;
  weapon1: WeaponType;
  weapon2: WeaponType;
}

interface Guild {
  id: string;
  name: string;
  members: GuildMember[];
}

const GuildDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [guild, setGuild] = useState<Guild | null>(null);

  useEffect(() => {
    // Aquí deberías hacer una llamada a tu API para obtener los detalles de la guild
    // Por ahora, usaremos datos de ejemplo
    const mockGuild: Guild = {
      id: id || '',
      name: 'Guild Example',
      members: [
        { id: '1', name: 'Member 1', weapon1: 'sns', weapon2: 'longsword' },
        { id: '2', name: 'Member 2', weapon1: 'wand', weapon2: 'staff' },
        { id: '3', name: 'Member 3', weapon1: 'bow', weapon2: 'crossbow' },
        { id: '4', name: 'Member 4', weapon1: 'dagger', weapon2: 'dagger' },
      ]
    };
    setGuild(mockGuild);
  }, [id]);

  if (!guild) {
    return <div className="min-h-screen bg-gray-900 text-white p-4">Cargando...</div>;
  }

  const getWeaponName = (weapon: WeaponType): string => {
    const weaponNames: Record<WeaponType, string> = {
      sns: 'Sword and Shield',
      longsword: 'Longsword',
      wand: 'Wand',
      bow: 'Bow',
      crossbow: 'Crossbow',
      dagger: 'Dagger',
      staff: 'Staff'
    };
    return weaponNames[weapon];
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <Link to="/mi-alianza" className="text-blue-400 hover:text-blue-300 mb-4 inline-block">
        &larr; Volver a Mi Alianza
      </Link>
      <h1 className="text-3xl font-bold mb-6">Detalles de Guild: {guild.name}</h1>
      
      <div className="bg-gray-800 rounded-lg shadow-xl p-6">
        <h2 className="text-2xl font-bold mb-4">Miembros de la Guild</h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-700">
                <th className="p-2 text-left">Nombre</th>
                <th className="p-2 text-center">Arma Principal</th>
                <th className="p-2 text-center">Arma Secundaria</th>
              </tr>
            </thead>
            <tbody>
              {guild.members.map(member => (
                <tr key={member.id} className="border-b border-gray-700">
                  <td className="p-2">{member.name}</td>
                  <td className="p-2 text-center">
                    <img 
                      src={`/icon/${member.weapon1}.png`} 
                      alt={getWeaponName(member.weapon1)} 
                      className="w-8 h-8 inline-block"
                      title={getWeaponName(member.weapon1)}
                    />
                  </td>
                  <td className="p-2 text-center">
                    <img 
                      src={`/icon/${member.weapon2}.png`} 
                      alt={getWeaponName(member.weapon2)} 
                      className="w-8 h-8 inline-block"
                      title={getWeaponName(member.weapon2)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GuildDetail;
