export interface Build {
  id: number;
  name: string;
  type: string;
  author: string;
  time: string;
  icon1: string;
  icon2: string;
  rating: number; 
  pveRating: number;
  pvpRating: number;
}
        
        export interface BuildsData {
          recommended: Build[];
          notRecommended: Build[];
          uncommon: Build[];
          specialization: {
            tank: Build[];
            healer: Build[];
            dps: Build[];
          };
          boss: Build[];
          openWorldMassive: Build[];
          openWorldSingleTarget: Build[];
        }
        
        export const builds: BuildsData = {
          recommended: [
            { id: 1, name: 'Archers Precision', type: 'Bow/Dagger', author: 'user1', time: '2 hr. ago', icon1: 'bow.png', icon2: 'dagger.png', rating: 8, pveRating: 4, pvpRating: 5 },
            { id: 2, name: 'Knights Valor', type: 'Longsword/Shield', author: 'user2', time: '3 hr. ago', icon1: 'longsword.png', icon2: 'sns.png', rating: 9, pveRating: 5, pvpRating: 4 },
            { id: 3, name: 'Mages Wisdom', type: 'Staff/Wand', author: 'user3', time: '1 hr. ago', icon1: 'staff.png', icon2: 'wand.png', rating: 8, pveRating: 5, pvpRating: 3 },
            { id: 4, name: 'Hunters Mark', type: 'Crossbow/Dagger', author: 'user4', time: '4 hr. ago', icon1: 'crossbow.png', icon2: 'dagger.png', rating: 7, pveRating: 3, pvpRating: 4 },
          ],
          notRecommended: [
            { id: 5, name: 'Unbalanced Duo', type: 'Wand/Longsword', author: 'user5', time: '1 day ago', icon1: 'wand.png', icon2: 'longsword.png', rating: 3, pveRating: 2, pvpRating: 1 },
            { id: 6, name: 'Awkward Combo', type: 'Staff/Shield', author: 'user6', time: '2 days ago', icon1: 'staff.png', icon2: 'sns.png', rating: 2, pveRating: 1, pvpRating: 2 },
            { id: 7, name: 'Mismatched Pair', type: 'Crossbow/Staff', author: 'user7', time: '3 days ago', icon1: 'crossbow.png', icon2: 'staff.png', rating: 4, pveRating: 2, pvpRating: 3 },
          ],
          uncommon: [
            { id: 8, name: 'Shadow Striker', type: 'Dagger/Dagger', author: 'user8', time: '5 hr. ago', icon1: 'dagger.png', icon2: 'dagger.png', rating: 6, pveRating: 3, pvpRating: 4 },
            { id: 9, name: 'Arcane Archer', type: 'Bow/Wand', author: 'user9', time: '6 hr. ago', icon1: 'bow.png', icon2: 'wand.png', rating: 7, pveRating: 4, pvpRating: 3 },
            { id: 10, name: 'Spell Sword', type: 'Longsword/Staff', author: 'user10', time: '7 hr. ago', icon1: 'longsword.png', icon2: 'staff.png', rating: 8, pveRating: 4, pvpRating: 5 },
          ],
          specialization: {
            tank: [
              { id: 11, name: 'Bulwark', type: 'Shield/Longsword', author: 'user11', time: '2 hr. ago', icon1: 'sns.png', icon2: 'longsword.png', rating: 9, pveRating: 5, pvpRating: 4 },
              { id: 12, name: 'Arcane Protector', type: 'Shield/Staff', author: 'user12', time: '3 hr. ago', icon1: 'sns.png', icon2: 'staff.png', rating: 7, pveRating: 4, pvpRating: 3 },
              { id: 13, name: 'Evasive Guardian', type: 'Shield/Dagger', author: 'user13', time: '4 hr. ago', icon1: 'sns.png', icon2: 'dagger.png', rating: 8, pveRating: 3, pvpRating: 5 },
            ],
            healer: [
              { id: 14, name: 'Life Bringer', type: 'Staff/Wand', author: 'user14', time: '3 hr. ago', icon1: 'staff.png', icon2: 'wand.png', rating: 9, pveRating: 5, pvpRating: 4 },
              { id: 15, name: 'Battle Medic', type: 'Staff/Shield', author: 'user15', time: '4 hr. ago', icon1: 'staff.png', icon2: 'sns.png', rating: 8, pveRating: 4, pvpRating: 3 },
              { id: 16, name: 'Swift Healer', type: 'Staff/Dagger', author: 'user16', time: '5 hr. ago', icon1: 'staff.png', icon2: 'dagger.png', rating: 7, pveRating: 3, pvpRating: 4 },
            ],
            dps: [
              { id: 17, name: 'Arcane Sniper', type: 'Crossbow/Wand', author: 'user17', time: '2 hr. ago', icon1: 'crossbow.png', icon2: 'wand.png', rating: 8, pveRating: 4, pvpRating: 5 },
              { id: 18, name: 'Dual Wielder', type: 'Longsword/Dagger', author: 'user18', time: '3 hr. ago', icon1: 'longsword.png', icon2: 'dagger.png', rating: 9, pveRating: 5, pvpRating: 4 },
              { id: 19, name: 'Spell Archer', type: 'Bow/Staff', author: 'user19', time: '4 hr. ago', icon1: 'bow.png', icon2: 'staff.png', rating: 8, pveRating: 4, pvpRating: 3 },
            ],
          },
          boss: [
            { id: 20, name: 'Boss Slayer', type: 'Longsword/Crossbow', author: 'user20', time: '4 hr. ago', icon1: 'longsword.png', icon2: 'crossbow.png', rating: 9, pveRating: 5, pvpRating: 3 },
            { id: 21, name: 'Dungeon Master', type: 'Staff/Shield', author: 'user21', time: '5 hr. ago', icon1: 'staff.png', icon2: 'sns.png', rating: 8, pveRating: 5, pvpRating: 2 },
            { id: 22, name: 'Raid Leader', type: 'Bow/Wand', author: 'user22', time: '6 hr. ago', icon1: 'bow.png', icon2: 'wand.png', rating: 7, pveRating: 4, pvpRating: 3 },
          ],
          openWorldMassive: [
            { id: 23, name: 'AoE Specialist', type: 'Staff/Wand', author: 'user23', time: '6 hr. ago', icon1: 'staff.png', icon2: 'wand.png', rating: 8, pveRating: 5, pvpRating: 3 },
            { id: 24, name: 'Crowd Controller', type: 'Crossbow/Staff', author: 'user24', time: '7 hr. ago', icon1: 'crossbow.png', icon2: 'staff.png', rating: 7, pveRating: 4, pvpRating: 3 },
            { id: 25, name: 'Swarm Destroyer', type: 'Longsword/Wand', author: 'user25', time: '8 hr. ago', icon1: 'longsword.png', icon2: 'wand.png', rating: 9, pveRating: 5, pvpRating: 4 },
          ],
          openWorldSingleTarget: [
            { id: 26, name: 'Precision Striker', type: 'Bow/Dagger', author: 'user26', time: '7 hr. ago', icon1: 'bow.png', icon2: 'dagger.png', rating: 8, pveRating: 4, pvpRating: 5 },
            { id: 27, name: 'Assassin', type: 'Dagger/Crossbow', author: 'user27', time: '8 hr. ago', icon1: 'dagger.png', icon2: 'crossbow.png', rating: 9, pveRating: 3, pvpRating: 5 },
            { id: 28, name: 'Duelist', type: 'Longsword/Dagger', author: 'user28', time: '9 hr. ago', icon1: 'longsword.png', icon2: 'dagger.png', rating: 7, pveRating: 3, pvpRating: 4 },
          ],
        };