import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Inicio from './components/Inicio';
import Ruleta from './components/Ruleta';
import BuildList from './components/BuildList';
import MiGuild from './components/MiGuild';
import Configuracion from './components/Configuracion';
import DKP from './components/DKP';
import Subasta from './components/Subasta';
import PartyGuild from './components/PartyGuild';
import MiAlianza from './components/MiAlianza'; // Nuevo import para MiAlianza
import GuildDetail from './components/GuildDetail';

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="flex flex-col md:flex-row min-h-screen relative">
        {/* Sidenav para escritorio */}
        <div className="hidden md:block fixed left-0 top-0 w-64 bg-gray-900 text-white min-h-screen p-4 z-30">
          <h2 className="text-xl font-bold mb-4">Menu</h2>
          <ul>
            <li className="mb-2">
              <Link to="/" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Inicio
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/ruleta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Ruleta
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/build" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Builds
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Mi Guild
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-alianza" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Mi Alianza
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/dkp" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                DKP
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/subasta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Subasta
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/party-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Party Guild
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/configuracion" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block">
                Configuración
              </Link>
            </li>
          </ul>
        </div>

        {/* Contenido principal */}
        <div className="flex-grow bg-black min-h-screen text-white md:ml-64 relative z-10 pb-16 md:pb-0">
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/ruleta" element={<Ruleta />} />
            <Route path="/build" element={<BuildList />} />
            <Route path="/mi-guild" element={<MiGuild />} />
            <Route path="/mi-alianza" element={<MiAlianza />} />
            <Route path="/dkp" element={<DKP />} />
            <Route path="/subasta" element={<Subasta />} />
            <Route path="/party-guild" element={<PartyGuild />} />
            <Route path="/configuracion" element={<Configuracion />} />
            <Route path="/guild/:id" element={<GuildDetail />} />
          </Routes>
        </div>

        {/* Menú inferior para móviles */}
        <div className={`md:hidden fixed bottom-0 left-0 right-0 bg-gray-900 text-white transition-all duration-300 ease-in-out ${isMenuOpen ? 'h-auto max-h-[80vh] overflow-y-auto' : 'h-12 overflow-hidden'} z-50`}>
          <div className="flex justify-between items-center px-4 h-12 sticky top-0 bg-gray-900">
            <span className="text-lg font-bold">Menu</span>
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                {isMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>
          <ul className="px-4 py-2">
            <li className="mb-2">
              <Link to="/" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Inicio
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/ruleta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Ruleta
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/build" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Builds
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Mi Guild
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-alianza" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Mi Alianza
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/dkp" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                DKP
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/subasta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Subasta
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/party-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Party Guild
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/configuracion" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
                Configuración
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Router>
  );
};

export default App;