import React, { useState, useMemo, useEffect } from 'react';

interface Item {
  id: string;
  name: string;
  description: string;
}

interface GuildMember {
  id: string;
  name: string;
  dkp: number;
}

interface AuctionRecord {
  id: string;
  itemId: string;
  minBid: number;
  burnDKP: boolean;
  status: 'active' | 'finished';
  winner?: string;
  winningBid?: number;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  winner: GuildMember | null;
  winningBid: number;
}

const WinnerModal: React.FC<ModalProps> = ({ isOpen, onClose, winner, winningBid }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
        <h2 className="text-2xl font-bold mb-4">Subasta Finalizada</h2>
        {winner ? (
          <>
            <p className="mb-2">Ganador: {winner.name}</p>
            <p className="mb-4">Puja ganadora: {winningBid} DKP</p>
          </>
        ) : (
          <p className="mb-4">No hubo ganador</p>
        )}
        <button 
          onClick={onClose}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

const Subasta: React.FC = () => {
  const items: Item[] = useMemo(() => [
    { id: '1', name: 'Espada legendaria', description: 'Una espada de gran poder' },
    { id: '2', name: 'Armadura de dragón', description: 'Protección contra el fuego' },
    { id: '3', name: 'Amuleto de los dioses', description: 'Aumenta todas las estadísticas' },
    // Añade más ítems según sea necesario
  ], []);

  const guildMembers: GuildMember[] = useMemo(() => {
    return Array.from({ length: 50 }, (_, index) => ({
      id: (index + 1).toString(),
      name: `Jugador ${index + 1}`,
      dkp: Math.floor(Math.random() * 1000) + 500 // DKP aleatorio entre 500 y 1499
    }));
  }, []);

  const [selectedItem, setSelectedItem] = useState<string>('');
  const [minBid, setMinBid] = useState<number>(0);
  const [burnDKP, setBurnDKP] = useState<boolean>(false);
  const [auctionStarted, setAuctionStarted] = useState<boolean>(false);
  const [currentBids, setCurrentBids] = useState<{[key: string]: number}>({});
  const [winner, setWinner] = useState<string | null>(null);
  const [auctions, setAuctions] = useState<AuctionRecord[]>([
    { id: 'a1', itemId: '1', minBid: 100, burnDKP: false, status: 'active' },
    { id: 'a2', itemId: '2', minBid: 200, burnDKP: true, status: 'finished', winner: '2', winningBid: 250 },
    // Añade más subastas de ejemplo según sea necesario
  ]);

  const [auctionParticipants, setAuctionParticipants] = useState<string[]>([]);
  const [maxBids, setMaxBids] = useState<{[key: string]: boolean}>({});
  const [inputBids, setInputBids] = useState<{[key: string]: string}>({});

  const [modalOpen, setModalOpen] = useState(false);
  const [winnerMember, setWinnerMember] = useState<GuildMember | null>(null);
  const [winningBid, setWinningBid] = useState(0);
  const [leadingBidder, setLeadingBidder] = useState<GuildMember | null>(null);

  useEffect(() => {
    if (auctionStarted) {
      const highestBid = Math.max(...Object.values(currentBids));
      const leaderId = Object.keys(currentBids).find(key => currentBids[key] === highestBid);
      const leader = guildMembers.find(m => m.id === leaderId) || null;
      setLeadingBidder(leader);
    }
  }, [currentBids, auctionStarted, guildMembers]);

  const handleItemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedItem(e.target.value);
  };

  const handleMinBidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinBid(Number(e.target.value));
  };

  const handleBurnDKPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBurnDKP(e.target.checked);
  };

  const startAuction = () => {
    if (!selectedItem || minBid <= 0) {
      alert('Por favor, selecciona un ítem y establece una puja mínima válida.');
      return;
    }
    const newAuction: AuctionRecord = {
      id: `a${auctions.length + 1}`,
      itemId: selectedItem,
      minBid: minBid,
      burnDKP: burnDKP,
      status: 'active'
    };
    setAuctions([...auctions, newAuction]);
    setAuctionStarted(true);
    setCurrentBids({});
    setWinner(null);
  };

  const handleBidChange = (memberId: string, value: string) => {
    setInputBids(prev => ({...prev, [memberId]: value}));
    
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      placeBid(memberId, numValue);
    }
  };

  const placeBid = (memberId: string, bidAmount: number) => {
    const member = guildMembers.find(m => m.id === memberId);
    if (member && bidAmount <= member.dkp && bidAmount >= minBid) {
      setCurrentBids(prev => ({...prev, [memberId]: bidAmount}));
      setMaxBids(prev => ({...prev, [memberId]: bidAmount === member.dkp}));
    }
  };

  const endAuction = () => {
    const highestBid = Math.max(...Object.values(currentBids));
    const winnerId = Object.keys(currentBids).find(key => currentBids[key] === highestBid);
    const winner = guildMembers.find(m => m.id === winnerId) || null;
    setWinnerMember(winner);
    setWinningBid(highestBid);
    setModalOpen(true);
    setAuctionStarted(false);

    if (burnDKP) {
      // Aquí iría la lógica para quemar los DKP de todos los miembros
      console.log('Quemando DKP de todos los miembros');
    }
  };

  const addParticipant = (memberId: string) => {
    if (!auctionParticipants.includes(memberId)) {
      setAuctionParticipants([...auctionParticipants, memberId]);
      setCurrentBids(prev => ({...prev, [memberId]: minBid}));
    }
  };

  const addAllParticipants = () => {
    const eligibleMembers = guildMembers.filter(member => member.dkp >= minBid);
    const newParticipants = eligibleMembers.map(member => member.id);
    setAuctionParticipants(newParticipants);
    const newBids = Object.fromEntries(eligibleMembers.map(member => [member.id, minBid]));
    setCurrentBids(prev => ({...prev, ...newBids}));
  };

  const removeParticipant = (memberId: string) => {
    setAuctionParticipants(auctionParticipants.filter(id => id !== memberId));
  };

  const bidAllDKP = (memberId: string) => {
    const member = guildMembers.find(m => m.id === memberId);
    if (member) {
      setCurrentBids(prev => ({...prev, [memberId]: member.dkp}));
      setInputBids(prev => ({...prev, [memberId]: member.dkp.toString()}));
      setMaxBids(prev => ({...prev, [memberId]: true}));
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Sistema de Subastas</h1>
        
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-2/3 space-y-6">
            {!auctionStarted ? (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h2 className="text-2xl font-bold mb-4">Configurar Subasta</h2>
                <div className="space-y-4">
                  <div>
                    <label className="block mb-2">Seleccionar Ítem</label>
                    <select 
                      value={selectedItem} 
                      onChange={handleItemChange}
                      className="w-full p-2 bg-gray-700 rounded"
                    >
                      <option value="">Selecciona un ítem</option>
                      {items.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2">Puja Mínima</label>
                    <input 
                      type="number" 
                      value={minBid} 
                      onChange={handleMinBidChange}
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                  </div>
                  <div className="flex items-center">
                    <input 
                      type="checkbox" 
                      checked={burnDKP} 
                      onChange={handleBurnDKPChange}
                      className="mr-2"
                    />
                    <label>Quemar DKP de la guild al finalizar</label>
                  </div>
                  <button 
                    onClick={startAuction}
                    className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Iniciar Subasta
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h2 className="text-2xl font-bold mb-4">Subasta en Progreso</h2>
                <p className="mb-4">Ítem: {items.find(i => i.id === selectedItem)?.name}</p>
                <p className="mb-4">Puja mínima: {minBid} DKP</p>
                {leadingBidder && (
                  <div className="bg-blue-600 p-2 rounded mb-4">
                    <p className="font-bold">Pujador Líder: {leadingBidder.name}</p>
                    <p>Puja actual: {currentBids[leadingBidder.id]} DKP</p>
                  </div>
                )}
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 max-h-96 overflow-y-auto">
                  {auctionParticipants.map(participantId => {
                    const member = guildMembers.find(m => m.id === participantId);
                    if (!member) return null;
                    const currentBid = currentBids[member.id] || minBid;
                    const isMaxBid = maxBids[member.id] || false;
                    return (
                      <div key={member.id} className="bg-gray-700 p-2 rounded flex flex-col space-y-1 text-xs">
                        <div className="flex justify-between items-center">
                          <span className="font-semibold">{member.name}</span>
                          <button 
                            onClick={() => removeParticipant(member.id)}
                            className="bg-red-500 text-white px-1 rounded text-xs"
                          >
                            X
                          </button>
                        </div>
                        <span>DKP: {member.dkp}</span>
                        <input 
                          type="number" 
                          placeholder="Puja"
                          className="p-1 bg-gray-600 rounded w-full"
                          value={inputBids[member.id] || currentBid}
                          onChange={(e) => handleBidChange(member.id, e.target.value)}
                        />
                        <span>Actual: {currentBid} DKP</span>
                        <button 
                          onClick={() => bidAllDKP(member.id)}
                          className={`text-white px-2 py-1 rounded text-xs w-full mt-1 ${
                            isMaxBid 
                              ? 'bg-blue-300 cursor-not-allowed' 
                              : 'bg-blue-500 hover:bg-blue-600'
                          }`}
                          disabled={isMaxBid}
                        >
                          Pujar Todo
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button 
                  onClick={endAuction}
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 mt-4"
                >
                  Finalizar Subasta
                </button>
              </div>
            )}

            {winner && (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6 mt-6">
                <h2 className="text-2xl font-bold mb-4">Resultado de la Subasta</h2>
                <p>Ganador: {guildMembers.find(m => m.id === winner)?.name}</p>
                <p>Puja ganadora: {currentBids[winner]} DKP</p>
              </div>
            )}
          </div>

          <div className="lg:w-1/3 space-y-6">
            {auctionStarted && (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h2 className="text-2xl font-bold mb-4">Miembros Elegibles</h2>
                <button 
                  onClick={addAllParticipants}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 mb-4"
                >
                  Agregar Todos Elegibles
                </button>
                <div className="grid grid-cols-2 gap-2 max-h-96 overflow-y-auto">
                  {guildMembers.filter(member => !auctionParticipants.includes(member.id)).map(member => (
                    <div key={member.id} className="bg-gray-700 p-2 rounded flex flex-col items-center justify-between">
                      <span className="text-sm text-center mb-1">{member.name}</span>
                      <span className="text-xs mb-2">DKP: {member.dkp}</span>
                      <button 
                        onClick={() => addParticipant(member.id)}
                        className={`text-white px-2 py-1 rounded text-xs w-full ${
                          member.dkp >= minBid 
                            ? 'bg-green-500 hover:bg-green-600' 
                            : 'bg-gray-500 cursor-not-allowed'
                        }`}
                        disabled={member.dkp < minBid}
                      >
                        {member.dkp >= minBid ? 'Agregar' : 'DKP Insuficiente'}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="bg-gray-800 rounded-lg shadow-xl p-6">
              <h2 className="text-2xl font-bold mb-4">Subastas</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">Activas</h3>
                  {auctions.filter(a => a.status === 'active').map(auction => (
                    <div key={auction.id} className="bg-gray-700 p-2 rounded mb-2">
                      <p className="font-semibold">{items.find(i => i.id === auction.itemId)?.name}</p>
                      <p className="text-sm">Puja mínima: {auction.minBid} DKP</p>
                      <p className="text-sm">{auction.burnDKP ? 'Quema DKP' : 'No quema DKP'}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Finalizadas</h3>
                  {auctions.filter(a => a.status === 'finished').map(auction => (
                    <div key={auction.id} className="bg-gray-700 p-2 rounded mb-2">
                      <p className="font-semibold">{items.find(i => i.id === auction.itemId)?.name}</p>
                      <p className="text-sm">Ganador: {guildMembers.find(m => m.id === auction.winner)?.name}</p>
                      <p className="text-sm">Puja ganadora: {auction.winningBid} DKP</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WinnerModal 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)} 
        winner={winnerMember} 
        winningBid={winningBid}
      />
    </div>
  );
};

export default Subasta;