import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { builds, Build } from '../data/buildsData';

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px 2px rgba(128, 0, 128, 0.5); }
  50% { box-shadow: 0 0 20px 10px rgba(128, 0, 128, 0.8); }
  100% { box-shadow: 0 0 5px 2px rgba(128, 0, 128, 0.5); }
`;

const fireAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const WeaponIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  transition: all 0.3s ease;
`;

const WeaponIcon = styled.div<{ selected?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.selected ? 
      'linear-gradient(45deg, #4a0080, #8f00ff, #4a0080)' : 
      'transparent'};
    background-size: 200% 200%;
    opacity: ${props => props.selected ? 0.6 : 0};
    transition: opacity 0.3s ease;
    animation: ${fireAnimation} 3s ease infinite;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }
`;

const BuildCard = styled.div`
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%);
  border: 1px solid #3a3a3a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
    border-color: #4a4a4a;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(128, 0, 128, 0.1) 0%, rgba(255, 0, 255, 0.1) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const WeaponGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const CategoryButton = styled.button<{ selected: boolean }>`
  padding: 8px;
  background-color: ${props => props.selected ? '#4a9eff' : '#2a2a2a'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3a7eff;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const RatingLabel = styled.label`
  font-weight: bold;
  margin-bottom: 4px;
`;

const StarRating = styled.div`
  display: flex;
`;

const Star = styled.span<{ filled: boolean }>`
  color: ${props => props.filled ? '#ffc107' : '#e4e5e9'};
  font-size: 24px;
  cursor: pointer;
`;

interface RatingProps {
  value: number;
  onChange: (value: number) => void;
}

const Rating: React.FC<RatingProps> = ({ value, onChange }) => {
  return (
    <StarRating>
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          filled={star <= value}
          onClick={() => onChange(star)}
        >
          ★
        </Star>
      ))}
    </StarRating>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const BuildListContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
    padding-right: 1rem;
  }
`;

const FormContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 25%;
  }
`;

const FixedFormWrapper = styled.div`
  @media (min-width: 768px) {
    position: sticky;
    top: 1rem;
    max-height: calc(100vh - 2rem);
    overflow-y: auto;
  }
`;

const BuildList: React.FC = () => {
  const [selectedWeapons, setSelectedWeapons] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [pveRating, setPveRating] = useState(0);
  const [pvpRating, setPvpRating] = useState(0);

  const weapons = [
    'bow.png', 'crossbow.png', 'dagger.png',
    'longsword.png', 'sns.png', 'staff.png', 'wand.png'
  ];

  const categories = [
    'recommended', 'notRecommended', 'uncommon', 'specialization',
    'boss', 'openWorldMassive', 'openWorldSingleTarget'
  ];

  const normalizeCategoryName = (category: string): string => {
    const words = category.split(/(?=[A-Z])/).join(' ').toLowerCase().split(' ');
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const generateEpicName = () => {
    const epicAdjectives = [
      'Celestial', 'Infernal', 'Ethereal', 'Mythic', 'Arcane',
      'Astral', 'Cosmic', 'Eldritch', 'Fabled', 'Legendary'
    ];
    
    const epicNouns = [
      'Harbinger', 'Nemesis', 'Sovereign', 'Paragon', 'Titan',
      'Colossus', 'Vanguard', 'Sentinel', 'Aegis', 'Zenith'
    ];

    const randomAdjective = epicAdjectives[Math.floor(Math.random() * epicAdjectives.length)];
    const randomNoun = epicNouns[Math.floor(Math.random() * epicNouns.length)];

    return `${randomAdjective} ${randomNoun}`;
  };

  const handleWeaponSelect = (weapon: string) => {
    setSelectedWeapons(prev => {
      if (prev.includes(weapon)) {
        return prev.filter(w => w !== weapon);
      } else {
        const newSelection = [...prev, weapon];
        return newSelection.slice(-2);  // Keep only the last two selections
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedWeapons.length !== 2 || !selectedCategory) {
      alert('Please select two weapons and a category');
      return;
    }

    const weapon1 = selectedWeapons[0].replace('.png', '');
    const weapon2 = selectedWeapons[1].replace('.png', '');
    const buildType = `${weapon1}/${weapon2}`;

    const newBuildData: Build = {
      id: Math.max(...Object.values(builds).flatMap(b => Array.isArray(b) ? b.map(build => build.id) : []).filter(id => typeof id === 'number')) + 1,
      name: generateEpicName(),
      icon1: selectedWeapons[0],
      icon2: selectedWeapons[1],
      type: buildType,
      author: 'User',
      time: new Date().toLocaleString(),
      rating: Math.round((pveRating + pvpRating) / 2),
      pveRating,
      pvpRating,
    };

    // Add the new build to the appropriate group
    if (Array.isArray(builds[selectedCategory as keyof typeof builds])) {
      (builds[selectedCategory as keyof typeof builds] as Build[]).push(newBuildData);
    } else if (selectedCategory === 'specialization') {
      builds.specialization.dps.push(newBuildData);
    }

    // Reset form
    setSelectedWeapons([]);
    setSelectedCategory('');
    setPveRating(0);
    setPvpRating(0);
  };

  const renderBuilds = (builds: Build[]) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4">
        {builds.map(build => (
          <BuildCard key={build.id} className="p-2 sm:p-4 rounded-lg flex items-center relative overflow-hidden h-auto sm:h-32">
            <img src={`/icon/${build.icon1.replace('.png', '_player.png')}`} alt="Player Icon 1" className="absolute top-0 left-0 w-1/2 h-full object-cover opacity-20 rounded-l-lg" />
            <img src={`/icon/${build.icon2.replace('.png', '_player.png')}`} alt="Player Icon 2" className="absolute top-0 right-0 w-1/2 h-full object-cover opacity-20 rounded-r-lg" />
            <div className="relative z-10 flex flex-col sm:flex-row items-center w-full">
              <div className="flex sm:flex-col items-center mb-2 sm:mb-0 sm:mr-4">
                <WeaponIconWrapper className="mr-2 sm:mr-0 sm:mb-1">
                  <img src={`/icon/${build.icon1}`} alt="Weapon Icon 1" className="w-8 h-8 sm:w-10 sm:h-10 rounded-full" />
                </WeaponIconWrapper>
                <WeaponIconWrapper>
                  <img src={`/icon/${build.icon2}`} alt="Weapon Icon 2" className="w-8 h-8 sm:w-10 sm:h-10 rounded-full" />
                </WeaponIconWrapper>
              </div>
              <div className="flex-grow text-center sm:text-left">
                <h2 className="text-sm sm:text-lg font-bold">{build.name}</h2>
                <p className="text-gray-400 text-xs sm:text-sm">{build.type}</p>
                <p className="text-gray-400 text-xs">{build.author}</p>
                <p className="text-gray-400 text-xs">{build.time}</p>
                <p className="text-gray-400 text-xs sm:text-sm">PvE Rating: {build.pveRating}/5</p>
                <p className="text-gray-400 text-xs sm:text-sm">PvP Rating: {build.pvpRating}/5</p>
              </div>
              <img src={`/icon/${build.icon1.replace('.png', '_player.png')}`} alt="Player Icon" className="w-16 h-16 sm:w-24 sm:h-24 rounded-full mt-2 sm:mt-0 sm:ml-4" />
            </div>
          </BuildCard>
        ))}
      </div>
    );
  };

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    checkIsDesktop();
    window.addEventListener('resize', checkIsDesktop);

    return () => {
      window.removeEventListener('resize', checkIsDesktop);
    };
  }, []);

  return (
    <LayoutContainer className="p-2 sm:p-4 bg-gray-900 text-white min-h-screen">
      <BuildListContainer>
        <h1 className="text-xl sm:text-2xl font-bold mb-4">Builds</h1>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Recommended Builds</h2>
          {renderBuilds(builds.recommended)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Not Recommended Builds</h2>
          {renderBuilds(builds.notRecommended)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Uncommon Builds</h2>
          {renderBuilds(builds.uncommon)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Specialization Builds</h2>
          <h3 className="text-lg font-bold mb-2">Tank</h3>
          {renderBuilds(builds.specialization.tank)}
          <h3 className="text-lg font-bold mb-2">Healer</h3>
          {renderBuilds(builds.specialization.healer)}
          <h3 className="text-lg font-bold mb-2">DPS</h3>
          {renderBuilds(builds.specialization.dps)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Boss Builds</h2>
          {renderBuilds(builds.boss)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Open World Massive Attack Builds</h2>
          {renderBuilds(builds.openWorldMassive)}
        </div>
        <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
          <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Open World Single Target Builds</h2>
          {renderBuilds(builds.openWorldSingleTarget)}
        </div>
      </BuildListContainer>
      <FormContainer>
        <FixedFormWrapper>
          <div className="bg-gray-800 p-4 rounded-lg">
            <h2 className="text-lg font-bold mb-4">Add New Build</h2>
            <form onSubmit={handleSubmit}>
              <h3 className="text-sm font-bold mb-2">Select Weapons (2)</h3>
              <WeaponGrid>
                {weapons.map(weapon => (
                  <WeaponIconWrapper
                    key={weapon}
                    onClick={() => handleWeaponSelect(weapon)}
                  >
                    <WeaponIcon selected={selectedWeapons.includes(weapon)}>
                      <img
                        src={`/icon/${weapon}`}
                        alt={weapon.replace('.png', '')}
                      />
                    </WeaponIcon>
                  </WeaponIconWrapper>
                ))}
              </WeaponGrid>
              <h3 className="text-md font-bold mb-2">Select Category</h3>
              <CategoryGrid>
                {categories.map(category => (
                  <CategoryButton
                    key={category}
                    selected={selectedCategory === category}
                    onClick={() => setSelectedCategory(category)}
                    type="button"
                  >
                    {normalizeCategoryName(category)}
                  </CategoryButton>
                ))}
              </CategoryGrid>
              <RatingContainer>
                <RatingLabel>PvE Rating:</RatingLabel>
                <Rating value={pveRating} onChange={setPveRating} />
              </RatingContainer>
              <RatingContainer>
                <RatingLabel>PvP Rating:</RatingLabel>
                <Rating value={pvpRating} onChange={setPvpRating} />
              </RatingContainer>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                type="submit"
              >
                Add Build
              </button>
            </form>
          </div>
        </FixedFormWrapper>
      </FormContainer>
    </LayoutContainer>
  );
};

export default BuildList;