import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Guild {
  id: string;
  name: string;
}

interface Event {
  id: string;
  title: string;
  description: string;
  date: string;
  createdBy: string;
}

interface Announcement {
  id: string;
  content: string;
  guildName: string;
  date: string;
}

interface WorldBossEvent {
  id: string;
  bossName: string;
  date: string;
  createdBy: string;
  participatingGuilds: string[];
  eventType: 'pve' | 'pvp';
}

interface AllianceInfo {
  id: string;
  name: string;
  guilds: Guild[];
  events: Event[];
  globalInfo: string;
  announcements: Announcement[];
  worldBossEvents: WorldBossEvent[];
}

const bossImages = [
  'Adentus.webp', 'Ahzreil.webp', 'Aridus.webp', 'Chernobog.webp', 'Cornelus.webp',
  'Daigon.webp', 'Deluzhnoa.webp', 'Excavator-9.webp', 'Grand Aelon.webp', 'Hermanos Manticus.webp',
  'Junobote.webp', 'Leviathan.webp', 'Malakar.webp', 'Minezerok.webp', 'Morokai.webp',
  'Nirma.webp', 'PakiloNaru.webp', 'Tevent.webp', 'QueenBlendy.webp', 'Talus.webp'
];

const GuildCard: React.FC<{ guild: Guild; backgroundImage: string }> = ({ guild, backgroundImage }) => {
  return (
    <Link 
      to={`/guild/${guild.id}`} 
      className="relative overflow-hidden rounded-lg shadow-xl"
    >
      <div 
        className="absolute inset-0 bg-cover bg-center z-0" 
        style={{ backgroundImage: `url(/img/${backgroundImage})` }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-purple-900 via-purple-600 to-transparent opacity-75 z-10" />
      <div className="relative z-20 p-4 h-full flex flex-col justify-end">
        <h3 className="text-xl font-bold text-white mb-2">{guild.name}</h3>
        <p className="text-sm text-gray-300">Click para ver detalles</p>
      </div>
    </Link>
  );
};

const BossCard: React.FC<{ boss: string; isSelected: boolean; onClick: () => void }> = ({ boss, isSelected, onClick }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div
      className={`relative w-full pb-[100%] cursor-pointer overflow-hidden`}
      onClick={onClick}
    >
      <div 
        className={`absolute inset-0 rounded-lg shadow-xl ${
          isSelected ? 'p-0.5 bg-yellow-500' : ''
        }`}
      >
        <div className="relative w-full h-full rounded-lg overflow-hidden">
          {!imageError ? (
            <img
              src={`/img/${boss}`}
              alt={boss.replace('.webp', '')}
              className="w-full h-full object-cover brightness-110 contrast-110"
              onError={() => setImageError(true)}
            />
          ) : (
            <div className="w-full h-full bg-gray-700 flex items-center justify-center">
              <span className="text-white text-xs">{boss.replace('.webp', '')}</span>
            </div>
          )}
          <div 
            className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-purple-900 via-purple-600 to-transparent opacity-75" 
            style={{height: '50%'}}
          />
          <div className="absolute inset-x-0 bottom-0 p-2">
            <h3 
              className="text-xs font-bold text-white truncate purple-outline"
              style={{textShadow: '-1px -1px 0 #8B5CF6, 1px -1px 0 #8B5CF6, -1px 1px 0 #8B5CF6, 1px 1px 0 #8B5CF6'}}
            >
              {boss.replace('.webp', '')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const MiAlianza: React.FC = () => {
  const [alliance, setAlliance] = useState<AllianceInfo | null>(null);
  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventDescription, setNewEventDescription] = useState('');
  const [newEventDate, setNewEventDate] = useState('');
  const [globalInfo, setGlobalInfo] = useState('');
  const [newGuildName, setNewGuildName] = useState('');
  const [allianceCode, setAllianceCode] = useState('ABC123');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [selectedBoss, setSelectedBoss] = useState('');
  const [newBossEventDate, setNewBossEventDate] = useState('');
  const [eventType, setEventType] = useState<'pve' | 'pvp'>('pve');
  const [otherGuildEvents, setOtherGuildEvents] = useState<WorldBossEvent[]>([
    { id: '1', bossName: 'Adentus.webp', date: '2023-06-15', createdBy: 'Guild X', participatingGuilds: ['Guild X', 'Guild Y'], eventType: 'pve' },
    { id: '2', bossName: 'Chernobog.webp', date: '2023-06-18', createdBy: 'Guild Y', participatingGuilds: ['Guild Y', 'Guild Z'], eventType: 'pvp' },
  ]);

  const guildBackgrounds = useMemo(() => {
    return alliance?.guilds.reduce((acc, guild) => {
      acc[guild.id] = bossImages[Math.floor(Math.random() * bossImages.length)];
      return acc;
    }, {} as Record<string, string>) || {};
  }, [alliance?.guilds]);

  useEffect(() => {
    // Aquí deberías cargar la información de la alianza desde tu backend
    // Por ahora, usaremos datos de ejemplo
    const mockAlliance: AllianceInfo = {
      id: '1',
      name: 'Alianza Ejemplo',
      guilds: [
        { id: '1', name: 'Guild 1' },
        { id: '2', name: 'Guild 2' },
      ],
      events: [
        { id: '1', title: 'Evento 1', description: 'Descripción del evento 1', date: '2023-06-01', createdBy: 'Guild 1' },
      ],
      globalInfo: 'Información global de la alianza',
      announcements: [
        { id: '1', content: 'Anuncio importante', guildName: 'Guild 1', date: '2023-06-01' },
        { id: '2', content: 'Evento próximo', guildName: 'Guild 2', date: '2023-06-02' },
      ],
      worldBossEvents: [],
    };
    setAlliance(mockAlliance);
    setGlobalInfo(mockAlliance.globalInfo);
  }, []);

  const handleJoinAlliance = () => {
    if (alliance) {
      setShowConfirmation(true);
    } else {
      sendJoinRequest();
    }
  };

  const sendJoinRequest = () => {
    // Aquí deberías implementar la lógica para enviar la solicitud de unión
    console.log(`Enviando solicitud para unirse a la alianza con código: ${allianceCode}`);
    // Resetear el código después de enviar la solicitud
    setAllianceCode('');
    // Aquí deberías manejar la respuesta del servidor y actualizar el estado en consecuencia
  };

  const confirmLeaveAlliance = () => {
    // Aquí deberías implementar la lógica para dejar la alianza actual
    console.log('Dejando la alianza actual');
    sendJoinRequest();
    setShowConfirmation(false);
    // Aquí deberías manejar la respuesta del servidor y actualizar el estado en consecuencia
  };

  const handleCreateEvent = () => {
    if (alliance) {
      const newEvent: Event = {
        id: Date.now().toString(),
        title: newEventTitle,
        description: newEventDescription,
        date: newEventDate,
        createdBy: 'Tu Guild', // Deberías obtener el nombre de tu guild actual
      };
      setAlliance({
        ...alliance,
        events: [...alliance.events, newEvent],
      });
      setNewEventTitle('');
      setNewEventDescription('');
      setNewEventDate('');
    }
  };

  const handleUpdateGlobalInfo = () => {
    if (alliance) {
      setAlliance({
        ...alliance,
        globalInfo: globalInfo,
      });
    }
  };

  const handleAddGuild = () => {
    if (alliance && newGuildName) {
      const newGuild: Guild = {
        id: Date.now().toString(),
        name: newGuildName,
      };
      const updatedGuilds = [...alliance.guilds, newGuild];
      setAlliance({
        ...alliance,
        guilds: updatedGuilds,
      });
      // Asignar una imagen de fondo a la nueva guild
      guildBackgrounds[newGuild.id] = bossImages[Math.floor(Math.random() * bossImages.length)];
      setNewGuildName('');
    }
  };

  const copyAllianceCode = () => {
    navigator.clipboard.writeText(allianceCode);
    // Opcionalmente, puedes mostrar un mensaje de éxito aquí
  };

  const handleAddAnnouncement = () => {
    if (alliance && newAnnouncement) {
      const newAnnouncementObj: Announcement = {
        id: Date.now().toString(),
        content: newAnnouncement,
        guildName: 'Tu Guild', // Deberías obtener el nombre de tu guild actual
        date: new Date().toISOString().split('T')[0],
      };
      setAlliance({
        ...alliance,
        announcements: [newAnnouncementObj, ...alliance.announcements],
      });
      setNewAnnouncement('');
    }
  };

  const handleCreateWorldBossEvent = () => {
    if (alliance && selectedBoss && newBossEventDate) {
      const newEvent: WorldBossEvent = {
        id: Date.now().toString(),
        bossName: selectedBoss,
        date: newBossEventDate,
        createdBy: 'Tu Guild', // Deberías obtener el nombre de tu guild actual
        participatingGuilds: ['Tu Guild'],
        eventType: eventType,
      };
      setAlliance({
        ...alliance,
        worldBossEvents: [...alliance.worldBossEvents, newEvent],
      });
      setSelectedBoss('');
      setNewBossEventDate('');
      setEventType('pve');
    }
  };

  const handleParticipateInWorldBossEvent = (eventId: string) => {
    setOtherGuildEvents(prevEvents => prevEvents.map(event => {
      if (event.id === eventId) {
        const isParticipating = event.participatingGuilds.includes('Tu Guild');
        return {
          ...event,
          participatingGuilds: isParticipating
            ? event.participatingGuilds.filter(guild => guild !== 'Tu Guild')
            : [...event.participatingGuilds, 'Tu Guild'],
        };
      }
      return event;
    }));
  };

  if (!alliance) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-4">
        <h1 className="text-3xl font-bold mb-6">Mi Alianza</h1>
        <p className="mb-4">No estás en ninguna alianza.</p>
        <div className="space-y-2">
          <input
            type="text"
            value={allianceCode}
            onChange={(e) => setAllianceCode(e.target.value)}
            placeholder="Código de la alianza"
            className="w-full p-2 bg-gray-700 rounded text-white"
          />
          <button 
            onClick={handleJoinAlliance}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Unirse a una Alianza
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <h1 className="text-3xl font-bold mb-6">Mi Alianza: {alliance.name}</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Eventos de Bosses de Mundo Abierto - Ocupa una columna completa */}
        <section className="bg-gray-800 rounded-lg shadow-xl p-6 lg:col-span-1 lg:row-span-2 flex flex-col">
          <h2 className="text-2xl font-bold mb-4">Eventos de Bosses de Mundo Abierto</h2>
          <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 mb-4 flex-grow overflow-y-auto">
            {bossImages.map((boss) => (
              <BossCard
                key={boss}
                boss={boss}
                isSelected={selectedBoss === boss}
                onClick={() => setSelectedBoss(boss)}
              />
            ))}
          </div>
          <div className="space-y-2 mt-4">
            <input
              type="date"
              value={newBossEventDate}
              onChange={(e) => setNewBossEventDate(e.target.value)}
              className="w-full p-2 bg-gray-700 rounded text-white"
            />
            <div className="flex items-center space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="pve"
                  checked={eventType === 'pve'}
                  onChange={() => setEventType('pve')}
                  className="mr-2"
                />
                <span className="text-white">Pacífico (PvE)</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="pvp"
                  checked={eventType === 'pvp'}
                  onChange={() => setEventType('pvp')}
                  className="mr-2"
                />
                <span className="text-white">Conflicto (PvP)</span>
              </label>
            </div>
            <button 
              onClick={handleCreateWorldBossEvent}
              className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              disabled={!selectedBoss || !newBossEventDate}
            >
              Publicar Evento de Boss
            </button>
          </div>
        </section>

        {/* Otras secciones */}
        <section className="bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Guilds en la Alianza</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
            {alliance.guilds.map(guild => (
              <GuildCard 
                key={guild.id} 
                guild={guild} 
                backgroundImage={guildBackgrounds[guild.id]}
              />
            ))}
          </div>
          <div className="mt-6">
            <input
              type="text"
              value={newGuildName}
              onChange={(e) => setNewGuildName(e.target.value)}
              placeholder="Nombre de la nueva guild"
              className="w-full p-2 bg-gray-700 rounded text-white mb-2"
            />
            <button 
              onClick={handleAddGuild}
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Agregar
            </button>
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Eventos de la Alianza</h2>
          <ul className="space-y-4 mb-4 max-h-48 overflow-y-auto">
            {alliance.events.map(event => (
              <li key={event.id} className="bg-gray-700 p-4 rounded">
                <h3 className="font-bold text-lg">{event.title}</h3>
                <p className="text-gray-300">{event.description}</p>
                <p className="text-sm text-gray-400">Fecha: {event.date}</p>
                <p className="text-sm text-gray-400">Creado por: {event.createdBy}</p>
              </li>
            ))}
          </ul>
          <div className="space-y-2">
            <input
              type="text"
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
              placeholder="Título del evento"
              className="w-full p-2 bg-gray-700 rounded text-white"
            />
            <textarea
              value={newEventDescription}
              onChange={(e) => setNewEventDescription(e.target.value)}
              placeholder="Descripción del evento"
              className="w-full p-2 bg-gray-700 rounded text-white"
              rows={3}
            />
            <input
              type="date"
              value={newEventDate}
              onChange={(e) => setNewEventDate(e.target.value)}
              className="w-full p-2 bg-gray-700 rounded text-white"
            />
            <button 
              onClick={handleCreateEvent}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Crear Evento
            </button>
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Eventos de otras Guilds</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-h-96 overflow-y-auto">
            {otherGuildEvents.map(event => (
              <div key={event.id} className="bg-gray-700 p-3 rounded flex flex-col h-full">
                <div className="flex items-center mb-2">
                  <img src={`/img/${event.bossName}`} alt={event.bossName} className="w-10 h-10 object-cover rounded mr-2" />
                  <div className="flex-grow min-w-0">
                    <h3 className="font-bold text-sm truncate">{event.bossName.replace('.webp', '')}</h3>
                    <p className="text-xs text-gray-400">Fecha: {event.date}</p>
                  </div>
                </div>
                <p className="text-xs mb-1 truncate">Creado por: {event.createdBy}</p>
                <p className="text-xs mb-2 truncate">
                  Guilds: {event.participatingGuilds.join(', ')}
                </p>
                <button 
                  onClick={() => handleParticipateInWorldBossEvent(event.id)}
                  className={`${
                    event.participatingGuilds.includes('Tu Guild')
                      ? 'bg-red-600 hover:bg-red-700'
                      : 'bg-green-600 hover:bg-green-700'
                  } text-white font-bold py-1 px-2 rounded text-xs mt-auto transition duration-300 w-full`}
                >
                  {event.participatingGuilds.includes('Tu Guild') ? 'No Participar' : 'Participar'}
                </button>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Anuncios</h2>
          <div className="space-y-2 mb-4">
            <textarea
              value={newAnnouncement}
              onChange={(e) => setNewAnnouncement(e.target.value)}
              placeholder="Nuevo anuncio"
              className="w-full p-2 bg-gray-700 rounded text-white"
              rows={3}
            />
            <button 
              onClick={handleAddAnnouncement}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Publicar Anuncio
            </button>
          </div>
          <ul className="space-y-4 max-h-96 overflow-y-auto">
            {alliance.announcements.map(announcement => (
              <li key={announcement.id} className="bg-gray-700 p-4 rounded">
                <p className="text-sm text-gray-400 mb-1">
                  {announcement.guildName} - {announcement.date}
                </p>
                <p>{announcement.content}</p>
              </li>
            ))}
          </ul>
        </section>

        {/* Eventos de Boss Creados - Ocupa todo el ancho */}
        <section className="bg-gray-800 rounded-lg shadow-xl p-6 lg:col-span-3">
          <h3 className="text-2xl font-bold mb-4">Eventos de Boss Creados</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {alliance?.worldBossEvents.map(event => (
              <div key={event.id} className="bg-gray-700 p-4 rounded-lg">
                <div className="flex items-center mb-2">
                  <img src={`/img/${event.bossName}`} alt={event.bossName} className="w-12 h-12 object-cover rounded-full mr-3" />
                  <div>
                    <p className="font-bold text-lg">{event.bossName.replace('.webp', '')}</p>
                    <p className="text-sm text-gray-400">Fecha: {event.date}</p>
                  </div>
                </div>
                <p className="text-sm mb-1">Tipo: {event.eventType === 'pve' ? 'Pacífico (PvE)' : 'Conflicto (PvP)'}</p>
                <p className="text-sm">Creado por: {event.createdBy}</p>
              </div>
            ))}
          </div>
        </section>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4">Confirmar cambio de Alianza</h2>
            <p className="mb-4">¿Estás seguro de que quieres dejar tu alianza actual y unirte a una nueva?</p>
            <div className="flex justify-end space-x-2">
              <button 
                onClick={() => setShowConfirmation(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Cancelar
              </button>
              <button 
                onClick={confirmLeaveAlliance}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiAlianza;