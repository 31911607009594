import React, { useState, useMemo } from 'react';

interface EventConfig {
  id: string;
  name: string;
  pointsPerParticipation: number;
  pointsExpiration: number; // en días
}

interface GuildMember {
  id: string;
  name: string;
  points: number;
}

const Configuracion: React.FC = () => {
  // Datos de ejemplo
  const eventsData: EventConfig[] = [
    { id: '1', name: 'Evento 1', pointsPerParticipation: 10, pointsExpiration: 30 },
    { id: '2', name: 'Evento 2', pointsPerParticipation: 20, pointsExpiration: 60 },
  ];

  // Generamos 40 miembros de ejemplo
  const initialMembers: GuildMember[] = Array.from({ length: 40 }, (_, i) => ({
    id: `${i + 1}`,
    name: `Miembro ${i + 1}`,
    points: Math.floor(Math.random() * 1000)
  }));

  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [eventConfig, setEventConfig] = useState<EventConfig | null>(null);
  const [memberPoints, setMemberPoints] = useState<GuildMember[]>(initialMembers);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const sortedMembers = useMemo(() => {
    return [...memberPoints].sort((a, b) => 
      sortOrder === 'desc' ? b.points - a.points : a.points - b.points
    );
  }, [memberPoints, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleEventChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = eventsData.find(event => event.id === e.target.value);
    setSelectedEvent(e.target.value);
    setEventConfig(selected || null);
  };

  const handleEventConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (eventConfig) {
      setEventConfig({
        ...eventConfig,
        [e.target.name]: Number(e.target.value),
      });
    }
  };

  const handleSaveEventConfig = () => {
    console.log('Guardando configuración del evento:', eventConfig);
    // Aquí iría la lógica para guardar la configuración del evento
  };

  const handleMemberPointsChange = (id: string, points: number) => {
    setMemberPoints(memberPoints.map(member => 
      member.id === id ? { ...member, points } : member
    ));
  };

  const handleSaveMemberPoints = () => {
    console.log('Guardando puntos de los miembros:', memberPoints);
    // Aquí iría la lógica para guardar los puntos de los miembros
  };

  const adjustPoints = (id: string, adjustment: number) => {
    setMemberPoints(memberPoints.map(member => 
      member.id === id ? { ...member, points: Math.max(0, member.points + adjustment) } : member
    ));
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gray-900 text-white p-4 space-y-6">
      {/* Tarjeta de Configuración de Eventos */}
      <div className="w-full max-w-4xl bg-gray-800 rounded-lg shadow-xl overflow-hidden">
        <div className="p-6 space-y-6">
          <h2 className="text-2xl font-semibold">Configuración de Eventos</h2>
          <select 
            value={selectedEvent} 
            onChange={handleEventChange}
            className="mb-4 p-2 bg-gray-700 rounded w-full"
          >
            <option value="">Selecciona un evento</option>
            {eventsData.map(event => (
              <option key={event.id} value={event.id}>{event.name}</option>
            ))}
          </select>
          
          {eventConfig && (
            <div className="space-y-4">
              <div>
                <label className="block mb-1">Puntos por participación:</label>
                <input 
                  type="number" 
                  name="pointsPerParticipation"
                  value={eventConfig.pointsPerParticipation}
                  onChange={handleEventConfigChange}
                  className="p-2 bg-gray-700 rounded w-full"
                />
              </div>
              <div>
                <label className="block mb-1">Caducidad de puntos (días):</label>
                <input 
                  type="number" 
                  name="pointsExpiration"
                  value={eventConfig.pointsExpiration}
                  onChange={handleEventConfigChange}
                  className="p-2 bg-gray-700 rounded w-full"
                />
              </div>
              <button 
                onClick={handleSaveEventConfig}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full transition duration-300"
              >
                Guardar configuración del evento
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-full max-w-6xl flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        {/* Tarjeta de Miembros */}
        <div className="w-full md:w-2/3 bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          <div className="p-6 space-y-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Puntos de los Miembros</h2>
              <button 
                onClick={handleSaveMemberPoints}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Guardar puntos de los miembros
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {memberPoints.map((member) => (
                <div key={member.id} className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">{member.name}</h3>
                  <div className="flex items-center space-x-2 mb-2">
                    <input 
                      type="number" 
                      value={member.points}
                      onChange={(e) => handleMemberPointsChange(member.id, Number(e.target.value))}
                      className="p-2 bg-gray-600 rounded w-full"
                    />
                  </div>
                  <div className="flex space-x-2">
                    <button 
                      onClick={() => adjustPoints(member.id, -10)}
                      className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded flex-1"
                    >
                      -10
                    </button>
                    <button 
                      onClick={() => adjustPoints(member.id, 10)}
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 rounded flex-1"
                    >
                      +10
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Nueva Tarjeta de Ranking (fija) */}
        <div className="w-full md:w-1/3">
          <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden md:sticky md:top-4">
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Ranking de Miembros</h2>
                <button 
                  onClick={toggleSortOrder}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  {sortOrder === 'desc' ? '↓' : '↑'}
                </button>
              </div>
              <div>
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="p-2 text-left">Nombre</th>
                      <th className="p-2 text-right">Puntos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedMembers.map((member, index) => (
                      <tr key={member.id} className={index % 2 === 0 ? 'bg-gray-750' : 'bg-gray-800'}>
                        <td className="p-2">{member.name}</td>
                        <td className="p-2 text-right">{member.points}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuracion;
