import React, { useState, useMemo } from 'react';

interface GuildMember {
  id: string;
  name: string;
  dkp: number;
  class: string;
}

interface Event {
  id: string;
  name: string;
  dkpReward: number;
}

const DKP: React.FC = () => {
  const events: Event[] = [
    { id: '1', name: 'Raid Semanal', dkpReward: 100 },
    { id: '2', name: 'Evento PvP', dkpReward: 50 },
    { id: '3', name: 'Dungeon Difícil', dkpReward: 75 },
  ];

  // Generamos 50 miembros de ejemplo una sola vez
  const guildMembers: GuildMember[] = useMemo(() => {
    return Array.from({ length: 50 }, (_, i) => ({
      id: `${i + 1}`,
      name: `Jugador ${i + 1}`,
      dkp: Math.floor(Math.random() * 1000),
      class: ['Guerrero', 'Mago', 'Sacerdote', 'Pícaro', 'Druida'][Math.floor(Math.random() * 5)]
    }));
  }, []); // El array vacío asegura que esto solo se ejecute una vez

  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [attendees, setAttendees] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);

  const handleEventChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEvent(e.target.value);
  };

  const handleMemberToggle = (memberId: string) => {
    setAttendees(prev => 
      prev.includes(memberId)
        ? prev.filter(id => id !== memberId)
        : [...prev, memberId]
    );
  };

  const handleSubmit = () => {
    const event = events.find(e => e.id === selectedEvent);
    if (!event) {
      alert('Por favor, selecciona un evento.');
      return;
    }
    if (attendees.length === 0) {
      alert('Por favor, selecciona al menos un asistente.');
      return;
    }
    
    setShowModal(true);
  };

  const handleCloseEvent = () => {
    console.log('Evento cerrado y DKP actualizados');
    setShowModal(false);
    setSelectedEvent('');
    setAttendees([]);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-[1920px] mx-auto">
        {/* Tarjeta única que contiene todo */}
        <div className="bg-gray-800 rounded-lg shadow-xl p-6">
          <div className="mb-6">
            <h2 className="text-2xl font-bold mb-4">Seleccionar Evento</h2>
            <div className="flex flex-col md:flex-row md:items-end md:space-x-4">
              <div className="flex-grow mb-4 md:mb-0">
                <select 
                  value={selectedEvent} 
                  onChange={handleEventChange}
                  className="w-full p-2 bg-gray-700 rounded"
                >
                  <option value="">Selecciona un evento</option>
                  {events.map(event => (
                    <option key={event.id} value={event.id}>{event.name} (+{event.dkpReward} DKP)</option>
                  ))}
                </select>
              </div>
              <button 
                onClick={handleSubmit}
                className="w-full md:w-auto bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Registrar Asistencia y Otorgar DKP
              </button>
            </div>
          </div>

          <h2 className="text-2xl font-bold mb-4">Miembros de la Guild</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4">
            {guildMembers.map(member => (
              <div 
                key={member.id} 
                className={`p-4 rounded-lg cursor-pointer transition-colors duration-200 ${
                  attendees.includes(member.id) 
                    ? 'bg-blue-600 hover:bg-blue-700' 
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => handleMemberToggle(member.id)}
              >
                <h3 className="font-semibold truncate">{member.name}</h3>
                <p className="text-sm text-gray-300">{member.class}</p>
                <p className="text-sm">{member.dkp} DKP</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-2xl font-bold mb-6 text-center">Resumen del Evento</h2>
            <div className="space-y-4 mb-6">
              <div className="flex flex-col items-center">
                <p className="text-lg mb-2">Número de asistentes:</p>
                <span className="text-3xl font-bold bg-green-600 text-white rounded-full w-16 h-16 flex items-center justify-center">
                  {attendees.length}
                </span>
              </div>
              <div className="text-center">
                <p className="text-lg mb-2">Evento:</p>
                <p className="text-xl font-semibold">
                  {events.find(e => e.id === selectedEvent)?.name}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-lg mb-2">DKP a otorgar:</p>
                <span className="text-3xl font-bold bg-green-600 text-white rounded-full px-4 py-2 min-w-[4rem] flex items-center justify-center">
                  {events.find(e => e.id === selectedEvent)?.dkpReward}
                </span>
              </div>
            </div>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition duration-300"
              >
                Volver
              </button>
              <button
                onClick={handleCloseEvent}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition duration-300"
              >
                Cerrar Evento
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DKP;
