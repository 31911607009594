import React, { useState, useMemo } from 'react';

interface GuildMember {
  id: string;
  name: string;
  class: string;
}

interface PartyMember extends GuildMember {
  role: 'DPS' | 'Tank' | 'Support';
}

interface Party {
  id: string;
  name: string;
  members: PartyMember[];
}

const PartyGuild: React.FC = () => {
  const guildMembers: GuildMember[] = useMemo(() => 
    Array.from({ length: 50 }, (_, i) => ({
      id: `member-${i + 1}`,
      name: `Member ${i + 1}`,
      class: ['Warrior', 'Mage', 'Priest', 'Rogue', 'Paladin', 'Druid', 'Hunter', 'Warlock'][Math.floor(Math.random() * 8)]
    }))
  , []);

  const [parties, setParties] = useState<Party[]>([]);
  const [selectedPartyId, setSelectedPartyId] = useState<string | null>(null);
  const [memberFilter, setMemberFilter] = useState('');

  const createNewParty = () => {
    const newParty: Party = {
      id: `party_${parties.length + 1}`,
      name: `Party ${parties.length + 1}`,
      members: []
    };
    setParties([...parties, newParty]);
    setSelectedPartyId(newParty.id);
  };

  const changeRole = (partyId: string, memberId: string, role: 'DPS' | 'Tank' | 'Support') => {
    setParties(parties.map(party => 
      party.id === partyId
        ? {
            ...party,
            members: party.members.map(m => 
              m.id === memberId ? { ...m, role } : m
            )
          }
        : party
    ));
  };

  const calculateSynergy = (party: Party) => {
    const roles = party.members.map(m => m.role);
    const hasDPS = roles.includes('DPS');
    const hasTank = roles.includes('Tank');
    const hasSupport = roles.includes('Support');

    if (hasDPS && hasTank && hasSupport) return 100;
    if ((hasDPS && hasTank) || (hasDPS && hasSupport) || (hasTank && hasSupport)) return 66;
    return 33;
  };

  const toggleMemberInParty = (member: GuildMember) => {
    if (!selectedPartyId) return;

    setParties(parties.map(party => {
      if (party.id === selectedPartyId) {
        const existingMember = party.members.find(m => m.id === member.id);
        if (existingMember) {
          // Remove member from party
          return { ...party, members: party.members.filter(m => m.id !== member.id) };
        } else if (party.members.length < 6) {
          // Add member to party
          return { ...party, members: [...party.members, { ...member, role: 'DPS' }] };
        }
      }
      return party;
    }));
  };

  const isInAnyParty = (memberId: string) => {
    return parties.some(party => party.members.some(member => member.id === memberId));
  };

  const filteredMembers = guildMembers.filter(member => 
    member.name.toLowerCase().includes(memberFilter.toLowerCase()) ||
    member.class.toLowerCase().includes(memberFilter.toLowerCase())
  );

  const chunkedMembers = useMemo(() => {
    const chunks: GuildMember[][] = [[], [], []];
    filteredMembers.forEach((member, index) => {
      chunks[index % 3].push(member);
    });
    return chunks;
  }, [filteredMembers]);

  return (
    <div className="h-screen bg-gray-900 text-white flex flex-col md:flex-row overflow-hidden">
      {/* Guild Members Section */}
      <div className="h-1/2 md:h-full md:w-1/2 p-4 flex flex-col overflow-hidden">
        <h2 className="text-2xl font-bold mb-4">Guild Members</h2>
        <input
          type="text"
          placeholder="Filter members..."
          value={memberFilter}
          onChange={(e) => setMemberFilter(e.target.value)}
          className="w-full p-2 mb-4 bg-gray-700 rounded text-white"
        />
        <div className="flex-1 bg-gray-800 rounded-lg shadow-xl p-4 overflow-hidden">
          {/* Mobile view */}
          <div className="h-full flex flex-col space-y-2 md:hidden">
            {chunkedMembers.map((row, rowIndex) => (
              <div key={rowIndex} className="flex-1 overflow-x-auto">
                <div className="flex space-x-2 pb-2 w-max">
                  {row.map((member) => (
                    <button
                      key={member.id}
                      onClick={() => toggleMemberInParty(member)}
                      className={`flex-shrink-0 bg-gray-700 p-2 rounded text-sm text-left ${
                        isInAnyParty(member.id) ? 'opacity-50' : 'hover:bg-gray-600'
                      }`}
                      disabled={isInAnyParty(member.id) && !selectedPartyId}
                      style={{ width: '120px' }}
                    >
                      <div className="font-semibold truncate">{member.name}</div>
                      <div className="text-gray-400">{member.class}</div>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* Desktop view */}
          <div className="hidden md:grid md:grid-cols-4 md:gap-4 h-full">
            {filteredMembers.map((member) => (
              <button
                key={member.id}
                onClick={() => toggleMemberInParty(member)}
                className={`flex-shrink-0 bg-gray-700 p-2 rounded text-sm text-left ${
                  isInAnyParty(member.id) ? 'opacity-50' : 'hover:bg-gray-600'
                }`}
                disabled={isInAnyParty(member.id) && !selectedPartyId}
              >
                <div className="font-semibold truncate">{member.name}</div>
                <div className="text-gray-400">{member.class}</div>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Parties Section */}
      <div className="h-1/2 md:h-full md:w-1/2 p-4 flex flex-col">
        <div className="flex items-center mb-4">
          <button 
            onClick={createNewParty}
            className="bg-green-600 hover:bg-green-700 text-white font-bold w-10 h-10 rounded-full flex items-center justify-center mr-4"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
          <h2 className="text-2xl font-bold">Parties</h2>
        </div>
        <div className="flex-1 overflow-x-auto md:overflow-y-auto">
          <div className="flex md:flex-col space-x-4 md:space-x-0 md:space-y-4 h-full md:h-auto">
            {parties.map(party => (
              <div key={party.id} className="flex-shrink-0 md:flex-shrink w-64 md:w-full bg-gray-800 rounded-lg shadow-xl p-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-xl font-semibold">{party.name} ({party.members.length}/6)</h3>
                  <button
                    onClick={() => setSelectedPartyId(party.id)}
                    className={`px-2 py-1 rounded ${
                      selectedPartyId === party.id ? 'bg-blue-600' : 'bg-gray-600 hover:bg-gray-500'
                    }`}
                  >
                    {selectedPartyId === party.id ? 'Selected' : 'Select'}
                  </button>
                </div>
                <div className="space-y-2 overflow-y-auto flex-1">
                  {party.members.map((member) => (
                    <div key={member.id} className="flex justify-between items-center bg-gray-700 p-2 rounded">
                      <span className="truncate">{member.name} ({member.class})</span>
                      <div className="flex items-center">
                        <select 
                          value={member.role}
                          onChange={(e) => changeRole(party.id, member.id, e.target.value as 'DPS' | 'Tank' | 'Support')}
                          className="bg-gray-600 text-white rounded mr-2 text-sm"
                        >
                          <option value="DPS">DPS</option>
                          <option value="Tank">Tank</option>
                          <option value="Support">Support</option>
                        </select>
                        <button
                          onClick={() => toggleMemberInParty(member)}
                          className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-sm"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2">
                  <div className="text-sm mb-1">Synergy:</div>
                  <div className="w-full bg-gray-700 rounded">
                    <div 
                      className={`h-2 rounded ${
                        calculateSynergy(party) === 100 ? 'bg-green-500' :
                        calculateSynergy(party) === 66 ? 'bg-yellow-500' : 'bg-red-500'
                      }`}
                      style={{ width: `${calculateSynergy(party)}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyGuild;