import React, { useState } from 'react';

// Importa las imágenes de los jugadores
const playerImages = [
  '/icon/bow_player.png',
  '/icon/staff_player.png',
  '/icon/longsword_player.png',
  '/icon/sns_player.png',
  // Añade aquí más rutas de imágenes si tienes más
];

const Ruleta: React.FC = () => {
  const [participantes, setParticipantes] = useState<Array<{ nombre: string, imagen: string }>>([]);
  const [nuevoParticipante, setNuevoParticipante] = useState('');
  const [indiceActual, setIndiceActual] = useState<number | null>(null);
  const [ganador, setGanador] = useState<string | null>(null);
  const [ganadorIndex, setGanadorIndex] = useState<number | null>(null);
  const [sorteando, setSorteando] = useState(false);
  const [progreso, setProgreso] = useState(0);
  const [historico, setHistorico] = useState<{ nombre: string, resultado: string }[]>([]);
  const [tipoSorteo, setTipoSorteo] = useState<'ganador' | 'alAgua'>('ganador');

  const jugadoresRecurrentes = ['Polarcito', 'Lianita', 'Tebixito', 'Fede', 'Warzone', 'Iguana', 'Fuego'];

  // Función para obtener una imagen aleatoria
  const getRandomPlayerImage = () => {
    return playerImages[Math.floor(Math.random() * playerImages.length)];
  };

  const sortear = () => {
    if (participantes.length < 2) return;
    setSorteando(true);
    setGanador(null);
    setGanadorIndex(null);
    setProgreso(100);
    let contador = 0;
    const totalCambios = 20;
    const intervalo = setInterval(() => {
      const nuevoIndice = Math.floor(Math.random() * participantes.length);
      setIndiceActual(nuevoIndice);
      contador++;
      setProgreso(((totalCambios - contador) / totalCambios) * 100);
      if (contador >= totalCambios) {
        clearInterval(intervalo);
        setSorteando(false);
        const participanteSeleccionado = participantes[nuevoIndice].nombre;
        setGanadorIndex(nuevoIndice);
        if (tipoSorteo === 'ganador') {
          setGanador(participanteSeleccionado);
          setHistorico([...historico, { nombre: participanteSeleccionado, resultado: 'Ganador' }]);
        } else {
          setGanador(`A tu casa pa: ${participanteSeleccionado}`);
          setHistorico([...historico, { nombre: participanteSeleccionado, resultado: 'A tu casa pa' }]);
        }
        setIndiceActual(null);
      }
    }, 100);
  };

  const agregarParticipante = () => {
    if (nuevoParticipante.trim() !== '') {
      const nombreTruncado = nuevoParticipante.trim().slice(0, 20);
      setParticipantes([...participantes, { nombre: nombreTruncado, imagen: getRandomPlayerImage() }]);
      setNuevoParticipante('');
    }
  };

  const agregarJugadorRecurrente = (jugador: string) => {
    if (!participantes.some(p => p.nombre === jugador)) {
      setParticipantes([...participantes, { nombre: jugador, imagen: getRandomPlayerImage() }]);
    }
  };

  const agregarTodosJugadoresRecurrentes = () => {
    const nuevosParticipantes = jugadoresRecurrentes
      .filter(jugador => !participantes.some(p => p.nombre === jugador))
      .map(jugador => ({ nombre: jugador, imagen: getRandomPlayerImage() }));
    setParticipantes([...participantes, ...nuevosParticipantes]);
  };

  const eliminarGanador = () => {
    if (ganador) {
      const nombreGanador = ganador.replace('A tu casa pa: ', '');
      setParticipantes(participantes.filter(participante => participante.nombre !== nombreGanador));
      setGanador(null);
      setGanadorIndex(null);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-900 text-white">
      <div className="flex flex-col items-center w-full max-w-4xl">
        <div className="w-full mb-4">
          <div className={`flex flex-col items-center bg-gray-800 text-white p-4 rounded-lg shadow-lg ${
            tipoSorteo === 'ganador' ? 'border-blue-500 border-2' : 'border-purple-500 border-2'
          }`}>
            {ganador && (
              <div className="text-center mb-4 w-full">
                <p className={`text-2xl font-bold ${tipoSorteo === 'ganador' ? 'text-blue-300' : 'text-purple-300'}`}>
                  RESULTADO: {ganador}
                </p>
                <button
                  onClick={eliminarGanador}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-2 w-full"
                >
                  {ganador.startsWith('A tu casa pa') ? 'Eliminar Perdedor' : 'Eliminar Ganador'}
                </button>
              </div>
            )}
            <h2 className="text-2xl font-bold mb-4">Participantes</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 mb-4 w-full">
              {participantes.map((participante, index) => (
                <div 
                  key={index} 
                  className={`p-2 border rounded text-center overflow-hidden relative ${
                    indiceActual === index ? 'border-green-500 border-4' : 
                    ganadorIndex === index ? 'border-green-500 border-4' : 'border-white'
                  }`}
                  style={{
                    backgroundImage: `url(${participante.imagen})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '80px',
                  }}
                >
                  <span className="block truncate absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-bold" title={participante.nombre}>
                    {participante.nombre}
                  </span>
                </div>
              ))}
            </div>
            <div className="w-full bg-gray-300 rounded-full h-4 mb-4">
              <div className="bg-blue-500 h-4 rounded-full" style={{ width: `${progreso}%` }}></div>
            </div>
            <div className="mb-4 w-full flex flex-col items-center">
              <input
                type="text"
                value={nuevoParticipante}
                onChange={(e) => setNuevoParticipante(e.target.value)}
                placeholder="Agregar participante"
                maxLength={20}
                className="px-4 py-2 border rounded mb-2 w-full text-black"
              />
              <button
                onClick={agregarParticipante}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 w-full"
              >
                Agregar
              </button>
            </div>
            <div className="mb-4 w-full flex flex-wrap justify-center">
              {jugadoresRecurrentes.map((jugador, index) => (
                <button
                  key={index}
                  onClick={() => agregarJugadorRecurrente(jugador)}
                  className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 mr-2 mb-2"
                >
                  {jugador}
                </button>
              ))}
            </div>
            <button
              onClick={agregarTodosJugadoresRecurrentes}
              className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 mb-4 w-full"
            >
              Agregar Todos los Jugadores Recurrentes
            </button>
            <div className="mb-4 w-full flex flex-col items-center">
              <label className="mr-2">Tipo de Sorteo:</label>
              <div className="flex items-center justify-center w-full">
                <label className="mr-4">
                  <input
                    type="radio"
                    value="ganador"
                    checked={tipoSorteo === 'ganador'}
                    onChange={() => setTipoSorteo('ganador')}
                    className="mr-2"
                  />
                  Ganador
                </label>
                <label>
                  <input
                    type="radio"
                    value="alAgua"
                    checked={tipoSorteo === 'alAgua'}
                    onChange={() => setTipoSorteo('alAgua')}
                    className="mr-2"
                  />
                  A tu casa pa
                </label>
              </div>
            </div>
            <button
              onClick={sortear}
              disabled={sorteando || participantes.length < 2}
              className={`px-4 py-2 text-white rounded hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-opacity-50 w-full disabled:opacity-50 disabled:cursor-not-allowed ${
                tipoSorteo === 'ganador' ? 'bg-blue-500 focus:ring-blue-500' : 'bg-purple-500 focus:ring-purple-500'
              }`}
            >
              {sorteando ? 'Sorteando...' : 'Sortear'}
            </button>
          </div>
        </div>
        <div className="w-full bg-gray-800 text-white p-4 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4">Histórico de Sorteos</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {historico.map((entry, index) => (
              <div 
                key={index} 
                className={`p-4 rounded bg-gray-700 shadow ${
                  entry.resultado === 'Ganador' 
                    ? 'border-2 border-blue-500' 
                    : 'border-2 border-purple-500'
                }`}
              >
                <p className="font-bold">Sorteo {index + 1}</p>
                <p className={entry.resultado === 'Ganador' ? 'text-blue-300' : 'text-purple-300'}>
                  {entry.resultado}: {entry.nombre}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ruleta;