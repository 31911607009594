import React, { useState } from 'react';
import * as XLSX from 'xlsx';

interface Miembro {
  id: string;
  nombre: string;
  arma1: string;
  arma2: string;
  dkp: number;  // Nuevo campo para DKP
}

const armas = [
  { nombre: 'Bow', icono: 'bow' },
  { nombre: 'Crossbow', icono: 'crossbow' },
  { nombre: 'Daggers', icono: 'dagger' },
  { nombre: 'Sword and shield', icono: 'sns' },
  { nombre: 'Greatsword', icono: 'longsword' },
  { nombre: 'Staff', icono: 'staff' },
  { nombre: 'Wand', icono: 'wand' }
];

const ArmaSelector: React.FC<{
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
}> = ({ name, value, onChange, label }) => {
  return (
    <div className="relative w-full">
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="w-full appearance-none bg-gray-800 text-white py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-700 focus:border-gray-500"
      >
        <option value="">{label}</option>
        {armas.map(arma => (
          <option key={arma.icono} value={arma.icono}>
            {arma.nombre}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
        </svg>
      </div>
    </div>
  );
};

const MiGuild: React.FC = () => {
  const [miembros, setMiembros] = useState<Miembro[]>([]);
  const [nuevoMiembro, setNuevoMiembro] = useState({ nombre: '', arma1: '', arma2: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [miembrosParaCargar, setMiembrosParaCargar] = useState<Miembro[]>([]);
  const [miembroAEliminar, setMiembroAEliminar] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNuevoMiembro({ ...nuevoMiembro, [name]: value });
  };

  const agregarMiembro = (e: React.FormEvent) => {
    e.preventDefault();
    if (nuevoMiembro.nombre && nuevoMiembro.arma1 && nuevoMiembro.arma2) {
      setMiembros([...miembros, {
        id: Date.now().toString(),
        nombre: nuevoMiembro.nombre,
        arma1: nuevoMiembro.arma1,
        arma2: nuevoMiembro.arma2,
        dkp: 0,  // Inicializamos DKP en 0 para nuevos miembros
      }]);
      setNuevoMiembro({ nombre: '', arma1: '', arma2: '' });
    }
  };

  const confirmarEliminarMiembro = (id: string) => {
    setMiembroAEliminar(id);
  };

  const eliminarMiembro = () => {
    if (miembroAEliminar) {
      setMiembros(miembros.filter(m => m.id !== miembroAEliminar));
      setMiembroAEliminar(null);
    }
  };

  // Función para calcular la similitud entre dos cadenas
  const similaridad = (s1: string | undefined, s2: string | undefined): number => {
    if (s1 === undefined || s2 === undefined) return 0;
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
    const len = Math.max(s1.length, s2.length);
    const dist = levenshteinDistance(s1, s2);
    return 1 - dist / len;
  };

  // Función para calcular la distancia de Levenshtein
  const levenshteinDistance = (s1: string, s2: string): number => {
    const m = s1.length;
    const n = s2.length;
    const d = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));

    for (let i = 1; i <= m; i++) {
      d[i][0] = i;
    }
    for (let j = 1; j <= n; j++) {
      d[0][j] = j;
    }

    for (let j = 1; j <= n; j++) {
      for (let i = 1; i <= m; i++) {
        if (s1[i - 1] === s2[j - 1]) {
          d[i][j] = d[i - 1][j - 1];
        } else {
          d[i][j] = Math.min(
            d[i - 1][j] + 1,  // deletion
            d[i][j - 1] + 1,  // insertion
            d[i - 1][j - 1] + 1  // substitution
          );
        }
      }
    }
    return d[m][n];
  };

  // Función para encontrar la mejor coincidencia de arma
  const encontrarMejorCoincidencia = (nombre: string | undefined): string => {
    if (nombre === undefined || nombre === '') return armas[0].icono;
    let mejorCoincidencia = armas[0].icono;
    let mejorSimilitud = -1;

    armas.forEach(arma => {
      const simNombre = similaridad(nombre, arma.nombre);
      const simIcono = similaridad(nombre, arma.icono);
      const maxSim = Math.max(simNombre, simIcono);
      if (maxSim > mejorSimilitud) {
        mejorSimilitud = maxSim;
        mejorCoincidencia = arma.icono;
      }
    });

    return mejorCoincidencia;
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        try {
          const bstr = evt.target?.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          
          let data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' }) as Array<string[]>;
          
          let startRow = 0;
          let headers = ['nombre', 'arma1', 'arma2'];

          if (data[0] && data[0].length >= 3 && 
              (data[0][0].toLowerCase() === 'nombre' || 
               data[0][1].toLowerCase() === 'arma1' || 
               data[0][2].toLowerCase() === 'arma2')) {
            headers = data[0].map(h => h.toLowerCase());
            startRow = 1;
          }

          const nuevosMiembros = data.slice(startRow)
            .filter(row => row.some(cell => cell !== ''))
            .map(row => {
              const miembro: { [key: string]: string } = {};
              headers.forEach((header, index) => {
                miembro[header] = row[index] || '';
              });
              if (miembro['nombre'] || miembro['arma1'] || miembro['arma2']) {
                return {
                  id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
                  nombre: miembro['nombre'] || 'Sin nombre',
                  arma1: encontrarMejorCoincidencia(miembro['arma1']),
                  arma2: encontrarMejorCoincidencia(miembro['arma2']),
                  dkp: 0,  // Inicializamos DKP en 0 para miembros cargados
                };
              }
              return null;
            })
            .filter((miembro): miembro is Miembro => miembro !== null);

          setMiembrosParaCargar(nuevosMiembros);
          setModalOpen(true);
        } catch (error) {
          console.error('Error al procesar el archivo:', error);
          alert('Hubo un error al procesar el archivo. Por favor, asegúrate de que el formato es correcto.');
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const confirmarCarga = () => {
    setMiembros(prevMiembros => [...prevMiembros, ...miembrosParaCargar]);
    setModalOpen(false);
    setMiembrosParaCargar([]);
  };

  const cancelarCarga = () => {
    setModalOpen(false);
    setMiembrosParaCargar([]);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold mb-4">Mi Guild</h1>
        
        <form onSubmit={agregarMiembro} className="mb-4 space-y-4 md:space-y-0 md:flex md:flex-wrap md:items-end md:space-x-2">
          <div className="flex-grow md:w-1/4">
            <input
              type="text"
              name="nombre"
              value={nuevoMiembro.nombre}
              onChange={handleInputChange}
              placeholder="Nombre"
              className="w-full p-2 border rounded bg-gray-800 text-white border-gray-700"
            />
          </div>
          <div className="md:w-1/4">
            <ArmaSelector
              name="arma1"
              value={nuevoMiembro.arma1}
              onChange={handleInputChange}
              label="Main Hand"
            />
          </div>
          <div className="md:w-1/4">
            <ArmaSelector
              name="arma2"
              value={nuevoMiembro.arma2}
              onChange={handleInputChange}
              label="Off Hand"
            />
          </div>
          <div className="md:w-auto">
            <button type="submit" className="w-full md:w-auto bg-blue-500 text-white p-2 rounded">
              Agregar Miembro
            </button>
          </div>
        </form>

        <div className="mb-4">
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="mb-2"
          />
          <p className="text-sm text-gray-400">Sube un archivo Excel con columnas: nombre, arma1, arma2</p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2">
          {miembros.map(miembro => (
            <div key={miembro.id} className="bg-gray-800 rounded-md shadow-sm overflow-hidden text-sm relative transition-all duration-300 ease-in-out hover:bg-purple-900 hover:shadow-lg group">
              <div className="p-2 text-center">
                <div className="text-yellow-400 font-semibold text-lg mb-1">{miembro.dkp} DKP</div>
                <h3 className="font-bold text-base mb-1 truncate">{miembro.nombre}</h3>
                <div className="flex justify-center space-x-2 mb-1">
                  <img src={`/icon/${miembro.arma1}.png`} alt={miembro.arma1} className="w-5 h-5" title={armas.find(a => a.icono === miembro.arma1)?.nombre} />
                  <img src={`/icon/${miembro.arma2}.png`} alt={miembro.arma2} className="w-5 h-5" title={armas.find(a => a.icono === miembro.arma2)?.nombre} />
                </div>
              </div>
              <div className="absolute top-1 right-1 text-xs text-gray-400 group-hover:text-purple-300">
                #{miembro.id.slice(0, 4)}
              </div>
              <button
                onClick={() => confirmarEliminarMiembro(miembro.id)}
                className="absolute bottom-1 right-1 text-xs text-gray-400 hover:text-red-500 transition duration-300 ease-in-out opacity-0 group-hover:opacity-100"
              >
                Eliminar
              </button>
            </div>
          ))}
        </div>

        {/* Modal para confirmar carga de miembros */}
        {modalOpen && (
          <ModalConfirmacion
            mensaje={`Se detectaron ${miembrosParaCargar.length} miembros. ¿Deseas confirmar la carga?`}
            onConfirm={confirmarCarga}
            onCancel={cancelarCarga}
          />
        )}

        {/* Modal para confirmar eliminación de miembro */}
        {miembroAEliminar && (
          <ModalConfirmacion
            mensaje="¿Estás seguro de que quieres eliminar este miembro?"
            onConfirm={eliminarMiembro}
            onCancel={() => setMiembroAEliminar(null)}
          />
        )}
      </div>
    </div>
  );
};

// Componente para el modal de confirmación
const ModalConfirmacion: React.FC<{
  mensaje: string;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ mensaje, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
        <p className="text-white mb-4 text-center">{mensaje}</p>
        <div className="flex justify-center space-x-2">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default MiGuild;